// wui.validate自定义拓展校验方法
// 双精度浮点数(不限制小数点位数)
$.validator.addMethod("double", function(value, element, param){
    //正整数
    var reNumber = /^\d+$/;
    //负整数
    var reNeNumber = /^-\d+$/;
    //正实数
    var reRealNumber1 = /^[1-9]\d*[.]\d+$/;  //非零开头
    var reRealNumber2 = /^0[.]\d+$/; //零开头
    //负实数
    var reNeRealNumber1 = /^-[1-9]\d*[.]\d+$/;  //非零开头
    var reNeRealNumber2 = /^-0[.]\d+$/; //零开头
    
    return this.optional(element) || value==="0" || reNumber.test(value) || reNeNumber.test(value) 
            || reRealNumber1.test(value) || reRealNumber2.test(value)
            || reNeRealNumber1.test(value)|| reNeRealNumber2.test(value);
    // return this.optional(element) || /^-?[0-9]+(.[0-9]{2})$/.test(value);
}, $.validator.format("请输入有效数字(包含)"));
// 单精度浮点数(可限制小数点位数)
$.validator.addMethod("float", function(value, element, param){
    // console.info(value);
    var maxLength = 2, minLength = 1;
    if(param){
        if(param.length == 1){
            maxLength = param[0];
        }else if(param.length == 2){
            minLength = param[0];
            maxLength = param[1];
        }else {
            maxLength = param;
        }
    }

    var re = new RegExp("^-?[0-9]+(.[0-9]{" + minLength + "," + maxLength + "})?$","g");

    return this.optional(element) || re.test(value);
}, $.validator.format("请输入到小数点后{0}位"));

$.validator.addMethod("postFloat", function(value, element, param){

    return this.optional(element) || /^\d+(\.{0,1}\d+){0,1}$/.test(value);

}, $.validator.format("只能输入非负数"));



$.validator.addMethod("byteRangeLength", function(value, element, param) {
    var length = value.length;
    for(var i = 0; i < value.length; i++){
        if(value.charCodeAt(i) > 127){
            length++;
        }
    }
  return this.optional(element) || ( length >= param[0] && length <= param[1] );   
}, $.validator.format("请确保输入的值在{0}-{1}个字节之间(一个中文字算2个字节)"));

$.validator.addMethod("noSpecialCaracters", function(value, element){
    // console.info(value);
    return this.optional(element) || /^[0-9a-zA-Z]+$/.test(value);
}, "只能输入数字和字母");

$.validator.addMethod("phone", function(phone_number, element){
    phone_number = phone_number.replace(/\(|\)|\s+|-/g, "");
    return this.optional(element) || phone_number.length > 9 && /^((13[0-9])|(14[1|4|5|6|7|8|9])|(15([0-3]|[5-9]))|(16[2|5|6|7])|(17([0-9]))|(18[0-9])|(19([1,8-9])))\d{8}$/.test(phone_number);
}, "请输入有效的手机号码");

// 车牌号
$.validator.addMethod("licenseplate", function(value, element){
    return this.optional(element) || /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/.test(value);
}, "请输入正确的车牌号码");

// 身份证号
$.validator.addMethod("idCard", function(value, element){
    return this.optional(element) || ((value.length == 15 || value.length == 18) && /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/.test(value));
}, "请输入正确的身份证号码");

$.validator.addMethod("extension", function(value, element, param) {
	param = typeof param === "string" ? param.replace(/,/g, "|") : "png|jpe?g|gif";
	return this.optional(element) || value.match(new RegExp("\\.(" + param + ")$", "i"));
}, "请输入有效的后缀");

$.validator.addMethod("ipv4", function(value, element) {
	return this.optional(element) || /^(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)$/i.test(value);
}, "请输入一个有效的IPv4地址");

$.validator.addMethod("ipv6", function(value, element) {
	return this.optional(element) || /^((([0-9A-Fa-f]{1,4}:){7}[0-9A-Fa-f]{1,4})|(([0-9A-Fa-f]{1,4}:){6}:[0-9A-Fa-f]{1,4})|(([0-9A-Fa-f]{1,4}:){5}:([0-9A-Fa-f]{1,4}:)?[0-9A-Fa-f]{1,4})|(([0-9A-Fa-f]{1,4}:){4}:([0-9A-Fa-f]{1,4}:){0,2}[0-9A-Fa-f]{1,4})|(([0-9A-Fa-f]{1,4}:){3}:([0-9A-Fa-f]{1,4}:){0,3}[0-9A-Fa-f]{1,4})|(([0-9A-Fa-f]{1,4}:){2}:([0-9A-Fa-f]{1,4}:){0,4}[0-9A-Fa-f]{1,4})|(([0-9A-Fa-f]{1,4}:){6}((\b((25[0-5])|(1\d{2})|(2[0-4]\d)|(\d{1,2}))\b)\.){3}(\b((25[0-5])|(1\d{2})|(2[0-4]\d)|(\d{1,2}))\b))|(([0-9A-Fa-f]{1,4}:){0,5}:((\b((25[0-5])|(1\d{2})|(2[0-4]\d)|(\d{1,2}))\b)\.){3}(\b((25[0-5])|(1\d{2})|(2[0-4]\d)|(\d{1,2}))\b))|(::([0-9A-Fa-f]{1,4}:){0,5}((\b((25[0-5])|(1\d{2})|(2[0-4]\d)|(\d{1,2}))\b)\.){3}(\b((25[0-5])|(1\d{2})|(2[0-4]\d)|(\d{1,2}))\b))|([0-9A-Fa-f]{1,4}::([0-9A-Fa-f]{1,4}:){0,5}[0-9A-Fa-f]{1,4})|(::([0-9A-Fa-f]{1,4}:){0,6}[0-9A-Fa-f]{1,4})|(([0-9A-Fa-f]{1,4}:){1,7}:))$/i.test(value);
}, "请输入一个有效的IPv6地址");

$.validator.addMethod("onlyChinese", function(value, element){
    // console.info(value);
    return this.optional(element) || /^[\\u4E00-\\u9FFF]+$/.test(value);
}, "只能输入中文字符");

$.validator.addMethod("onlyDoubleChars", function(value, element){
    // console.info(value);
    return this.optional(element) || /^[^\\x00-\\xff]+$/.test(value);
}, "只能输入双字节字符(包括汉字在内)");

// 任意自然数(如0,1,2,3,4,5)以及它们的负数
$.validator.addMethod("integer", function(value, element){
    return this.optional( element ) || /^((\-?[0-9]*)|(\+?[0-9]*))$/.test( value );
}, "只能输入整数");

// 任意自然数(如0,1,2,3,4,5)
$.validator.addMethod("natNumber", function(value, element){
    return this.optional( element ) || /^\+?[0-9]*$/.test( value );
}, "只能输入自然数(如0,1,2,3,4,5)");

// 正整数(如1,2,3,4,5)
$.validator.addMethod("posInteger", function(value, element){
    return this.optional( element ) || /^\+?[1-9][0-9]*$/.test( value );
}, "只能输入正整数(如1,2,3,4,5)");

// 传真号码
$.validator.addMethod("fax", function(value, element){
    return this.optional( element ) || /^(\d{3,4}-)?\d{7,8}$/.test( value );
}, "请输入正确的传真号码");

// same as url, but TLD is optional
$.validator.addMethod("url2", function(value, element) {
	return this.optional(element) || /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)*(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(value);
}, "请输入正确的URL地址");
// 日期比较
// param[0]: 比较对象id
// param[1]: 比较关联词：early、notEarly、equal、notEqual、late、notLate
// param[2]: 天数
$.validator.addMethod("dateCompare", function(value, element, param) {
    // alert(value)
    var isDate = this.optional(element) || !/Invalid|NaN/.test(new Date(value && value.replace(/-/ig, '/')).toString());
    if(isDate){
        var $compareTarget = null;
        if(param[0].indexOf(".") == 0){
            $compareTarget = $(param[0]).eq(0);
        }else if(param[0].indexOf(".") > 0){
            var selectors = param[0].split(".");
            $compareTarget = $('[aria-wui-grid-name="' + selectors[0] + '"][aria-wui-col-edit-name="' + selectors[1] + '"]').eq(0);
        }else{
            $compareTarget = $("#" + param[0]);
        }
        if($compareTarget.length > 0){
            if(!$compareTarget.val()){
                return true;
            }else{
                var currentDate = value;
                var compareDate = $compareTarget.val();
                // 统一日期格式为yyyy-mm-dd

                // 作比较
                // currentDate <(early) 		compareDate  5天: compareDate - currentDate >  5	currentDate + 5 <  compareDate
                // currentDate >=(notEarly) 	compareDate  5天: compareDate - currentDate <= 5	currentDate + 5 >= compareDate
                // currentDate ==(equal) 	    compareDate  5天: compareDate - currentDate == 0	currentDate == compareDate + 5
                // currentDate !=(notEqual) 	compareDate  5天: compareDate - currentDate != 0	currentDate != compareDate + 5
                // currentDate >(late) 		    compareDate  5天: compareDate - currentDate <  -5	currentDate >  compareDate + 5
                // currentDate <=(notLate) 	    compareDate  5天: compareDate - currentDate >= -5	currentDate <= compareDate + 5
                try{
                    var currentDateStamp = Date.parse(currentDate ? currentDate.replace(/-/ig, '/') : currentDate);
                    var compareDateStamp = Date.parse(compareDate ? compareDate.replace(/-/ig, '/') : compareDate);
                    // dateSpan = sDate2 - sDate1;
                    var realDateDiff = compareDateStamp - currentDateStamp;
                    // console.info("realDateDiff", realDateDiff);
                    // console.info("param[1]", param[1]);
                    // console.info("dateDiff", dateDiff);
                    var dateDiff = param[2] || 0;
                    var diffStamp = dateDiff * (24 * 3600 * 1000);
                    switch(param[1]){
                        case "early":
                            if(realDateDiff > diffStamp ){
                                return true;
                            }
                            break;
                        case "notEarly":
                            // 不早于几天，那就代表一定要晚于 compareDate 几天，
                            // 但是如果是晚于的话，realDateDiff绝对是负值，但是 diffStamp 绝对是自然数
                            // 所以晚于的话需要把负值取绝对值进行对比
                            // 但是还有一种情况就是如果取值是早于 compareDate
                            // 那获取到的值肯定是正的，但是这样就不符合这个不早于的条件了，直接return false即可
                            // 下面的晚于也是同样的思维
                            if (realDateDiff <= 0) {
                                realDateDiff = Math.abs(realDateDiff)
                            }else {
                                return false
                            }
                            if(realDateDiff >= diffStamp ){
                                return true;
                            }
                            break;
                        case "equal":
                            if(realDateDiff == diffStamp ){
                                return true;
                            }
                            break;
                        case "notEqual":
                            if(realDateDiff != diffStamp ){
                                return true;
                            }
                            break;
                        case "late":
                            if (realDateDiff < 0) {
                                realDateDiff = Math.abs(realDateDiff)
                            }else {
                                return false
                            }
                            if(realDateDiff > diffStamp ){
                                return true;
                            }
                            break;
                        case "notLate":
                            if(realDateDiff >= diffStamp ){
                                return true;
                            }
                            break;
                        default: 
                            return false;
                    }
                }catch(ex){
                    console.log(ex);
                    return false;
                }
                return false;
            }
        }
        // var isDate2 = !/Invalid|NaN/.test(new Date(value).toString());
    }else{
        return false;
    }
}, $.validator.format("请输入{3}组件【#{0}】{2}天的日期"));

// 动态正则表达式
$.validator.addMethod("exp", function(value, element, param){
    // var re = new RegExp("^\\d+$","gim");
    // var re = new RegExp(param[0], param[1]);
    // var re = eval(param[0])
    if(param[0] == "regExp"){
        var regExp = eval(param[1]);
        return this.optional( element ) || regExp.test( value );
    }else{
        // 替换占位符:value,element
        try{
            var source = param[1];
            source = source.replace(new RegExp("\\{0\\}", "g"), function () {
                return value;
            });
            var jsExp = eval(source);
            return this.optional( element ) || jsExp;
        }catch(ex){
            return false;
        }
    }
    
}, "请输入符合正则结果的字符串");

// 动态正则表达式
$.validator.addMethod("serviceValid", function(value, element, param){
    // var re = new RegExp("^\\d+$","gim");
    // var re = new RegExp(param[0], param[1]);
    // var re = eval(param[0])
    if(param[0] == "regExp"){
        var regExp = eval(param[1]);
        return this.optional( element ) || regExp.test( value );
    }else{
        // 替换占位符:value,element
        try{
            var source = param[1];
            source = source.replace(new RegExp("\\{0\\}", "g"), function () {
                return value;
            });
            var jsExp = eval(source);
            return this.optional( element ) || jsExp;
        }catch(ex){
            return false;
        }
    }
    
}, "请输入符合服务规则的字符串");

// 自定义校验方法格式: $.validator.addMethod( ruleName, ruleMethod, errorMessage );