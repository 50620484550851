// 拓展JQuery插件，用于监听元素宽度高度变化
;(function ($, window, undefined) {
    var elems = $([]),
        jq_resize = $.resize = $.extend($.resize, {}),
        timeout_id,
        str_setTimeout = 'setTimeout',
        str_resize = 'resize',
        str_data = str_resize + '-special-event',
        str_delay = 'delay',
        str_throttle = 'throttleWindow';
    jq_resize[str_delay] = 250;
    jq_resize[str_throttle] = true;

    $.event.special[str_resize] = {
        setup: function () {
            if (!jq_resize[str_throttle] && this[str_setTimeout]) {
                return false;
            }
            var elem = $(this);
            elems = elems.add(elem);
            $.data(this, str_data, {
                w: elem.width(),
                h: elem.height()
            });
            if (elems.length === 1) {
                loopy();
            }
        },
        teardown: function () {
            if (!jq_resize[str_throttle] && this[str_setTimeout]) {
                return false;
            }
            var elem = $(this);
            elems = elems.not(elem);
            elem.removeData(str_data);
            if (!elems.length) {
                clearTimeout(timeout_id);
            }
        },
        add: function (handleObj) {
            if (!jq_resize[str_throttle] && this[str_setTimeout]) {
                return false;
            }
            var old_handler;

            function new_handler(e, w, h) {
                var elem = $(this),
                data = $.data(this, str_data);
                data.w = w !== undefined ? w : elem.width();
                data.h = h !== undefined ? h : elem.height();
                old_handler.apply(this, arguments);
            }

            if ($.isFunction(handleObj)) {
                old_handler = handleObj;
                return new_handler;
            } else {
                old_handler = handleObj.handler;
                handleObj.handler = new_handler;
            }
        }
    };
    function loopy() {
        timeout_id = window[str_setTimeout](function () {
            window.timeout_elems = elems;
            elems.each(function () {
                var elem = $(this);
                    localName = elem[0].localName,
                    type = elem[0].type,
                    FRAME_NAME = elem[0].FRAME_NAME;
                    if (localName == 'script' || type == 'hidden' || localName == 'style' || FRAME_NAME == 'wui') {
                        
                    }else {
                        // var width = elem.width(),
                        var width = elem[0].offsetWidth,
                            height = elem[0].offsetHeight,
                        // height = elem.height(),
                        data = $.data(this, str_data);

                        // console.log(width + ":" +data.w + ":" + width1)
                        // console.log(height + ":" + data.h + ":" +height1)
                        if (width && height && (width !== data.w || height !== data.h)) {
                            elem.trigger(str_resize, [data.w = width, data.h = height]);
                        }
                    }
            });
            // elems.each(function () {
            //     var elem = $(this);
            //         localName = elem[0].localName,
            //         type = elem[0].type;
            //         var width = elem.width(),    
            //         height = elem.height(),
            //         data = $.data(this, str_data);
            //         if (width !== data.w || height !== data.h) {
            //             elem.trigger(str_resize, [data.w = width, data.h = height]);
            //         }
                    
            // });
            
            loopy();
        }, jq_resize[str_delay]);
    }
    
})(jQuery, this);

// 树图标拓展
;(function($, window, document, undefined) {

    "use strict";

    /* *****************************************************************************
     * Private functions and variables
     */

    function _getIcon(opts, type){
        return opts.map[type];
    }
    if($.ui && $.ui.fancytree){
        $.ui.fancytree.registerExtension({
            name: "awesome4",
            version: "4.4.0",
            // Default options for this extension.
            // options: {
            //     map: {
            //         _addClass: "fa ",
            //         checkbox: "fa-square-o",
            //         checkboxSelected: "fa-check-square-o",
            //         checkboxUnknown: "fa-square",
            //         dragHelper: "fa-arrow-right",
            //         dropMarker: "fa-long-arrow-right",
            //         error: "fa-warning",
            //         expanderClosed: "fa-caret-right",
            //         expanderLazy: "fa-angle-right",
            //         expanderOpen: "fa-caret-down",
            //         loading: "fa-spinner fa-pulse",
            //         nodata: "fa-meh-o",
            //         noExpander: "",
            //         radio: "fa-circle-thin",  // "fa-circle-o"
            //         radioSelected: "fa-circle",
            //         // Default node icons.
            //         // (Use tree.options.icon callback to define custom icons based on node data)
            //         doc: "fa-file-o",
            //         docOpen: "fa-file-o",
            //         folder: "fa-folder-o",
            //         folderOpen: "fa-folder-open-o"
            //     }
            // },

            treeInit: function(ctx){
                var tree = ctx.tree;
                this._superApply(arguments);
                tree.$container.addClass("fancytree-ext-glyph");
            },
            nodeRenderStatus: function(ctx) {
                var icon, res, span,
                    node = ctx.node,
                    $span = $(node.span),
                    opts = ctx.options.glyph,
                    map = opts.map;

                res = this._superApply(arguments);

                if( node.isRoot() ){
                    return res;
                }
                span = $span.children("span.fancytree-expander").get(0);
                if( span ){
                    // if( node.isLoading() ){
                        // icon = "loading";
                    if( node.expanded && node.hasChildren() ){
                        icon = "expanderOpen";
                    }else if( node.isUndefined() ){
                        icon = "expanderLazy";
                    }else if( node.hasChildren() ){
                        icon = "expanderClosed";
                    }else{
                        icon = "noExpander";
                    }
                    span.className = map["_addClass"] + map[icon];
                }

                if( node.tr ){
                    span = $("td", node.tr).find("span.fancytree-checkbox").get(0);
                }else{
                    span = $span.children("span.fancytree-checkbox").get(0);
                }
                if( span ){
                    icon = node.selected ? "checkboxSelected" : (node.partsel ? "checkboxUnknown" : "checkbox");
                    span.className = map["_addClass"] + map[icon];
                }

                // Standard icon (note that this does not match .fancytree-custom-icon,
                // that might be set by opts.icon callbacks)
                span = $span.children("span.fancytree-icon").get(0);
                if( span ){
                    if( node.statusNodeType ){
                        icon = _getIcon(opts, node.statusNodeType); // loading, error
                    }else if( node.folder ){
                        icon = node.expanded && node.hasChildren() ? _getIcon(opts, "folderOpen") : _getIcon(opts, "folder");
                    }else{
                        icon = node.expanded ? _getIcon(opts, "docOpen") : _getIcon(opts, "doc");
                    }
                    span.className = map["_addClass"] + icon;
                }
                return res;
            },
            nodeSetStatus: function(ctx, status, message, details) {
                var res, span,
                    opts = ctx.options.glyph,
                    node = ctx.node;

                res = this._superApply(arguments);

                if( status === "error" || status === "loading" || status === "nodata" ){
                    if(node.parent){
                        span = $("span.fancytree-expander", node.span).get(0);
                        if( span ) {
                            span.className = "fancytree-expander " + _getIcon(opts, status);
                        }
                    }else{ //
                        span = $(".fancytree-statusnode-" + status, node[this.nodeContainerAttrName])
                            .find("span.fancytree-icon").get(0);
                        if( span ) {
                            span.className = "fancytree-icon " + _getIcon(opts, status);
                        }
                    }
                }
                return res;
            }
        });
    }
        
}(jQuery, window, document));

/* IE兼容性代码 Start */
// 定义console变量,否则在IE下有可能因为未定义而报错
if(!window.console){
    window.console = {
        log : function(){},
        info: function(){},
        error: function(){},
        warn: function(){}
    };
}

// 兼容IE8判断数组方法
if (!Array.isArray) {
    Array.isArray = function(arg) {
        return Object.prototype.toString.call(arg) === '[object Array]';
    };
}

// 判断元素是否存在数组中，返回索引或者-1
if(!Array.indexOf){
    Array.prototype.indexOf = function(el){
        for (var i=0,n=this.length; i<n; i++){
            if (this[i] === el){
                return i;
            }
        }
        return -1;
    }  
}

/* IE兼容性代码 End */

/* 对象拓展代码 Start */
// 扩展contains方法：判断一个字符串是否包含一个子串,返回true或者false
// if(!Array.contains){
//     Array.prototype.contains = function(item){ 
//         // return RegExp("\\b"+item+"\\b").test(this); 
//         var i = this.length;
//         while (i--) {
//             if (this[i] === obj) {
//                 return true;
//             }
//         }
//         return false;
//     }
// }

// if(!Array.insert){
//     Array.prototype.insert = function (index, item) {  
//         this.splice(index, 0, item);  
//     };
// }

// if(!Array.lastIndexOf){
//     Array.prototype.lastIndexOf=function(item){
//         var len=this.length;
//         for(var i=len;i>=0;i--){
//             if(this[i]===item){
//                 return len-i;
//             }
//         }
//         return -1;
//     };
// }



// 扩展startWith方法：判断字符串对象是否以传入的str参数开头,若参数为空或不以参数字符串开头,则返回false,否则返回true
if(!String.startWith){
    String.prototype.startWith=function(str){ 
        if(str == null || str == "" || this.length == 0 || str.length > this.length) 
            return false;

        if(this.substr(0, str.length) == str) 
            return true; 
        else 
            return false;
    }
}

// 判断字符串对象是否以传入的str参数结尾,若参数为空或不以参数字符串结尾,则返回false,否则返回true
if(!String.endWith){
    String.prototype.endWith=function(str){
        if(str==null||str==""||this.length==0||str.length>this.length)
            return false;
        if(this.substring(this.length-str.length)==str)
            return true;
        else
            return false;
        return true;
    }
}

if(!String.insert){
    ///*
     // 字符串中插入子字符串
     // @param  {[type]} insertStr     [插入的字符串]
     // @param  {[type]} positionIndex [插入位置,从0开始,默认是末尾]
     // @return {String}               [插入完成之后的字符串,并不改变字符串对象]
     ///
    String.prototype.insert = function(insertStr, positionIndex){
        if(positionIndex == undefined){
            positionIndex = this.length;
        }
        if(this.length==0 || positionIndex >= this.length){
            return this + insertStr;
        }else{
            var preString = this.substring(0, positionIndex);
            var lastString = this.substring(positionIndex);
            // console.info(preString);
            // console.info(lastString);
            var newstr = preString + insertStr + lastString;
            // for(var i = 0; i < str.length; i += sn){
            //     var tmp=str.substring(i, i+sn);
            //     newstr+=tmp+flg;
            // }
            return newstr;
        }
    }
}

if(!String.replaceAll){
    // String.prototype.replaceAll=function(str1, str2){

    //     var reg = new RegExp(str1, "gmi");

    //     return this.replace(reg, str2);
    // }

    String.prototype.replaceAll = function(search, replacement) {
        var target = this;
        return target.replace(new RegExp(search, 'g'), replacement);
    };
}

/*
 * 获取字符串的宽度
 * @Author   Chenzx
 * @DateTime 2018-12-07
 * @param    {String}   font 传入的字符串字体大小和字体名,默认是'12px arial'
 * @return   {[type]}        [description]
 */
String.prototype.width = function(font) {
    var f = font || '12px arial',
        o = $('<div>' + this + '</div>')
            .css({
                'position': 'absolute', 
                'float': 'left', 
                'white-space': 'nowrap', 
                'visibility': 'hidden', 
                'font': f
            })
            .appendTo($('body')),
            w = o.width();

    o.remove();
    return w;
}

// if(!String.lastIndexOf){
//     String.prototype.lastIndexOf=function(searchvalue, fromindex){
//         fromindex = fromindex || 0；
//         var len = this.length;
//         for(var i = len; i >= fromindex; i--){
//             if(this[i] === searchvalue){
//                 return len-i;
//             }
//         }
//         return -1;
//     };
// }

// 拓展JS去空格的方法
// 去除字符串两边空格,并返回去除之后的字符串
String.prototype.trim=function(){
    return this.replace(/(^\s*)|(\s*$)/g, "");
}
// 去除字符串左边空格,并返回去除之后的字符串
String.prototype.ltrim=function(){
    return this.replace(/(^\s*)/g,"");
}
// 去除字符串右边空格,并返回去除之后的字符串
String.prototype.rtrim=function(){
    return this.replace(/(\s*$)/g,"");
}
String.prototype.trimAll=function(){
    return this.replace(/\s*/g, "");
}

//得到左边的字符串
String.prototype.Left = function(len){
    if(isNaN(len)||len==null){
        len = this.length;
    }else{
        if(parseInt(len)<0||parseInt(len)>this.length){
            len = this.length;
        }
    }
    return this.substr(0,len);
}

// 得到右边的字符串
String.prototype.right = function(len){
    if(isNaN(len)||len==null){
        len = this.length;
    }else{
        if(parseInt(len)<0||parseInt(len)>this.length){
            len = this.length;
        }
    }
    return this.substring(this.length-len,this.length);
}
//方法一:逐个字符检查是否中文字符
String.prototype.getByteLen = function() {  
    var len = 0;  
    for (var i=0; i<this.length; i++) 
    {
        if ((this.charCodeAt(i) & 0xff00) != 0)
            len ++;
        len ++;  
    }  
    return len;  
}

if (!Object.keys){ 
    Object.keys = function(o) {
      if (o !== Object(o))
        throw new TypeError('Object.keys called on a non-object');
      var k=[],p;
      for (p in o) if (Object.prototype.hasOwnProperty.call(o,p)) k.push(p);
      return k;
    }
}

/**
 * <p>判断JQuery节点是否存在</p>
 * @namespace jQuery
 * @class exist
 * @constructor 
 * @return {boolean} [传入JQuery是否存在]
 * @example
 *  
 *   js: 
 *   
        $("body").exist();  // true

        $('div.test').exist(function() {
            this.append('<p>存在!</p>');
        });
 *
 */
$.fn.exist = function(callback){
    var args = [].slice.call(arguments, 1);

    if (this.length) {
        if(callback && typeof(callback) === "function"){
            callback.call(this, args);
            return true;
        }else{
            return true;
        }
    }

    return false;

    // if($(this).length>=1){
    //     return true;
    // }
    // return false;
};

 window['undefined'] = window['undefined'];
/* 对象拓展代码 End */
